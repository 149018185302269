// @flow
import * as React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Grid from '@material-ui/core/Grid';
import TextField from '~plugins/form/components/TextField';

import Form, {
  type Props as FormProps,
} from '~plugins/zoho-form/components/Form';
import useHandleOnSubmitMapValues from '~plugins/zoho-form/hooks/useHandleOnSubmitMapValues';
import withPartialSuccessReponseSnackbar from '~hocs/withPartialSuccessReponseSnackbar';
import FormLoadingButton from '~components/FormLoadingButton';
import StyledTextField from '~components/StyledTextField';
import CheckboxWithLabel from '~plugins/form/components/CheckboxWithLabel';
import RichText from '~plugins/prismic/components/RichText';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';
import useRecaptcha from '~hooks/useRecaptcha';
import fetchRecaptchaValidation from '~helpers/fetchRecaptchaValidation';

import type { ResellerFormValues } from './types';
import messages from './messages';
import handleSubmitMapValues from './helpers/handleSubmitMapValues';
import type { PrismicResellerForm } from '~schema';

const EnhancedForm = withPartialSuccessReponseSnackbar<
  ResellerFormValues,
  FormProps<ResellerFormValues>,
>()(Form);

const darkColor = '#f4f5f8';

export type ClassKey = 'root' | 'checkbox' | 'action' | 'disabledButton';

const styles = (theme: Theme) => ({
  root: {},
  checkbox: {
    textAlign: 'left',
    '& a': {
      color: theme.palette.primary.main,
    },
  },
  action: {
    marginTop: theme.spacing(2),
  },
  disabledButton: {
    backgroundColor: `${theme.palette.primary.dark} !important`,
  },
});

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<$Diff<FormProps<ResellerFormValues>, { onSubmitMapValues: any }>>,
  node: ?PrismicResellerForm,
  postCode: ?string,
  company: ?string,
  className?: string,
  onSubmitMapValues?: $ElementType<
    FormProps<ResellerFormValues>,
    'onSubmitMapValues',
  >,
};

const ResellerForm = ({
  initialValues,
  postCode,
  company,
  onSubmitMapValues,
  node,
  className,
  classes,
  ...props
}: Props) => {
  const intl = useIntl();
  const [recaptcha, token] = useRecaptcha();

  const handleSubmit = React.useCallback(
    () => fetchRecaptchaValidation(token),
    [token],
  );

  return (
    <EnhancedForm
      {...props}
      node={node}
      messages={messages}
      onSubmit={handleSubmit}
      onSubmitMapValues={useHandleOnSubmitMapValues({
        onSubmitMapValues,
        handleSubmitMapValues,
      })}
      initialValues={React.useMemo(
        () => ({
          name: '',
          email: '',
          body: '',
          postcode: postCode || undefined,
          company: company || undefined,
          ...initialValues,
        }),
        [initialValues],
      )}
      className={classes.root}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledTextField
            type="text"
            name="name"
            TextField={TextField}
            label={
              node?.data?.label_name?.text || (
                <FormattedMessage {...messages.labels.name} />
              )
            }
            inputProps={{ 'aria-label': 'Name input field' }}
            color={darkColor}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTextField
            type="email"
            name="email"
            TextField={TextField}
            label={
              node?.data?.label_email?.text || (
                <FormattedMessage {...messages.labels.email} />
              )
            }
            inputProps={{ 'aria-label': 'Email input field' }}
            color={darkColor}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <StyledTextField
            type="text"
            name="body"
            TextField={TextField}
            multiline
            rows={4}
            label={
              node?.data?.label_body?.text || (
                <FormattedMessage {...messages.labels.body} />
              )
            }
            inputProps={{ 'aria-label': 'Body input field' }}
            color={darkColor}
            fullWidth
          />
        </Grid>

        {hasRichTextFieldValue(node?.data?.label_accept_privacy_policy) ? (
          <Grid item xs={12}>
            <CheckboxWithLabel
              name="acceptPrivacyPolicy"
              type="checkbox"
              indeterminate={false}
              required
              label={<RichText {...node?.data?.label_accept_privacy_policy} />}
              aria-label={
                node?.data?.label_accept_privacy_policy?.text ||
                intl.formatMessage(messages.labels.privacyPolicy)
              }
              className={classes.checkbox}
              color="primary"
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>

      {recaptcha}

      <FormLoadingButton
        type="submit"
        variant="contained"
        color="primary"
        spinnerColor="secondary"
        aria-label="download"
        fullWidth
        className={classes.action}
        classes={{ disabled: classes.disabledButton }}
      >
        {node?.data?.label_submit?.text || (
          <FormattedMessage {...messages.labels.submit} />
        )}
      </FormLoadingButton>
    </EnhancedForm>
  );
};

ResellerForm.defaultProps = {
  className: undefined,
  onSubmitMapValues: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'ResellerForm' })(ResellerForm);
