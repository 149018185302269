// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';

import messages from '../messages';

export default function useGroupedResellersByCountry(resellers: ?Array<?any>) {
  const intl = useIntl();
  const ALL_COUNTRIES_KEY = intl.formatMessage(messages.allCountriesKey);

  const groupedResellers = React.useMemo(() =>
    resellers
      ? groupBy<string, *>(
          resellers,
          country => country?.data?.country?.document?.data?.code?.text,
        )
      : null,
  );

  const toPairsGroupedResellers = toPairs(groupedResellers);

  return toPairsGroupedResellers.reduce(
    (acc, resellersGroup) => ({
      ...acc,
      [ALL_COUNTRIES_KEY]: resellers,
      [resellersGroup[0]]: resellersGroup[1],
    }),
    {},
  );
}
