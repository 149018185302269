// @flow
import type { MessageDescriptor } from 'react-intl';

import type { ResellerFormValues } from './types';

const prefix = 'resellerForm';

export default {
  labels: {
    ...({
      name: {
        id: `${prefix}.labels.name`,
        defaultMessage: 'Name',
        description: 'Name field label',
      },
      email: {
        id: `${prefix}.labels.email`,
        defaultMessage: 'E-mail',
        description: 'E-mail field label',
      },
      body: {
        id: `${prefix}.labels.body`,
        defaultMessage: 'Description',
        description: 'Body field label',
      },
      acceptPrivacyPolicy: {
        id: `${prefix}.labels.acceptPrivacyPolicy`,
        defaultMessage: `Accept privacy policy`,
        description: 'Accept privacy policy field label',
      },
    }: $ObjMap<
      $Diff<ResellerFormValues, { postcode: any, company: any }>,
      <V>() => MessageDescriptor,
    >),
    submit: ({
      id: `${prefix}.labels.submit`,
      defaultMessage: 'Submit',
      description: 'Submit button label',
    }: MessageDescriptor),
    privacyPolicy: ({
      id: `${prefix}.labels.privacyPolicy`,
      defaultMessage: 'privacy policy',
      description: 'Privacy policy label',
    }: MessageDescriptor),
  },
  errors: {
    email: ({
      id: `${prefix}.errors.email`,
      defaultMessage: 'Must be a valid email address',
      description: 'Error shown for an email field that has an invalid value',
    }: MessageDescriptor),
    required: ({
      id: `${prefix}.errors.required`,
      defaultMessage: 'Required',
      description: 'Error shown for a required field that has no value',
    }: MessageDescriptor),
    mustAcceptPrivacyPolicy: ({
      id: `${prefix}.errors.mustAcceptPrivacyPolicy`,
      defaultMessage: 'Must accept privacy policy',
      description: 'Error shown when privacy policy field is not accepted',
    }: MessageDescriptor),
  },
  onSuccess: ({
    id: `${prefix}.onSuccess`,
    defaultMessage: 'Submission sent!',
    description: 'Message shown when a submission has been succesfully sent',
  }: MessageDescriptor),
  onError: ({
    id: `${prefix}.onError`,
    defaultMessage: 'Could not send submission.',
    description: 'Message shown when a submission has not been accepted',
  }: MessageDescriptor),
  onReject: ({
    id: `${prefix}.onReject`,
    defaultMessage: 'Could not send submission.',
    description: 'Message shown when a submission has not been sent',
  }: MessageDescriptor),
};
