// @flow
import type { Styles } from './types';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';

const styles: Styles = theme => ({
  root: {
    textAlign: 'left',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(0,0,0,.15)',
    },
  },
  wrapper: {
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          paddingTop: theme.spacing(getBreakPointFactor(breakpoint) * 2),
          paddingBottom: theme.spacing(getBreakPointFactor(breakpoint) * 2),
        },
      }),
      {},
    ),
  },
  type: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
});

export default styles;
