// @flow
import type { ResellerFormValues } from '../types';

export default function handleSubmitMapValues(
  originalValues: ResellerFormValues,
) {
  return {
    createLead: {
      Last_Name: originalValues.name,
      Email: originalValues.email,
      Description: originalValues.body,
      Zip_Code: originalValues.postcode || undefined,
      Company: originalValues.company || undefined,
    },
  };
}
