// @flow
import { graphql, useStaticQuery } from 'gatsby';

import useFindCurrentLocaleNode from '~plugins/prismic/hooks/useFindCurrentLocaleNode';
import type { Query, PrismicResellerForm } from '~schema';

const query = graphql`
  query UseResellerFormDefaultQuery {
    allPrismicResellerForm {
      nodes {
        lang
        id
        data {
          name {
            text
            html
          }
          title {
            text
            html
          }
          description {
            text
            html
          }
          label_name {
            text
            html
          }
          label_email {
            text
            html
          }
          label_body {
            text
            html
          }
          label_accept_privacy_policy {
            text
            html
          }
          label_submit {
            text
            html
          }
          error_email {
            text
            html
          }
          error_must_accept_privacy_policy {
            text
            html
          }
          error_required {
            text
            html
          }
          event_error {
            text
            html
          }
          event_reject {
            text
            html
          }
          event_success {
            text
            html
          }
        }
      }
    }
  }
`;

export default function useResellerForm(): ?PrismicResellerForm {
  return useFindCurrentLocaleNode<PrismicResellerForm>({
    nodes: useStaticQuery<Query>(query).allPrismicResellerForm?.nodes,
    toQuery: prismicResellerForm => ({ prismicResellerForm }),
    fromQuery: data => data?.prismicResellerForm,
  });
}
