// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import map from 'lodash/map';
import find from 'lodash/find';

import type { Props } from './types';
import styles from './styles';
import messages from './messages';

const ResellersFilters = ({
  countries,
  countriesInfo,
  results,
  value,
  onChange,
  onInput,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const countriesList = countries && Object.keys(countries);
  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Paper elevation={0} square className={classes.wrapper}>
        <Typography className={classes.results}>
          {results ? (
            <FormattedMessage
              {...messages.results}
              values={{
                results: results,
              }}
            />
          ) : (
            <FormattedMessage {...messages.enterPostcode} />
          )}
        </Typography>
        <TextField
          variant="outlined"
          label={<FormattedMessage {...messages.postcode} />}
          onChange={onInput}
          className={classes.input}
        />
        <Select
          value={value}
          onChange={onChange}
          variant="outlined"
          displayEmpty
          className={classes.selector}
        >
          {map(countriesList, countryCode => (
            <MenuItem key={countryCode} value={countryCode}>
              {find(
                countriesInfo,
                country => country?.data?.code?.text === countryCode,
              )?.data?.name?.text || countryCode}
            </MenuItem>
          ))}
        </Select>
      </Paper>
    </div>
  );
};

ResellersFilters.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'ResellersFilters' })(ResellersFilters);
