// @flow
import * as React from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import type { PrismicResellerForm } from '~schema';
import hasRichTextFieldValue from '~plugins/prismic/helpers/hasRichTextFieldValue';

import type { ResellerFormValues } from './types';
import messages from './messages';

const useResellerFormValidationSchema = (node: ?PrismicResellerForm) => {
  const intl = useIntl();
  return React.useMemo(
    () =>
      yup.object<ResellerFormValues>({
        name: yup
          .string()
          .required(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.required),
          ),
        email: yup
          .string()
          .required(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.required),
          )
          .email(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.email),
          ),
        body: yup
          .string()
          .required(
            node?.data?.error_required?.text ||
              intl.formatMessage(messages.errors.required),
          ),
        postcode: yup.string().nullable(true),
        company: yup.string().nullable(true),
        acceptPrivacyPolicy:
          // add validation only when there is label, as it will only be shown then
          hasRichTextFieldValue(node?.data?.label_accept_privacy_policy)
            ? yup
                .boolean()
                .test(
                  'isTrue',
                  node?.data?.error_must_accept_privacy_policy?.text ||
                    intl.formatMessage(messages.errors.mustAcceptPrivacyPolicy),
                  value => value === true,
                )
            : yup.mixed(),
      }),
    [node],
  );
};

export default useResellerFormValidationSchema;
