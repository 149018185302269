// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import map from 'lodash/map';

import type { PrismicReseller } from '~schema';

import messages from './messages';

export type ClassKey = 'root';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  name: string,
  resellers?: ?Array<?PrismicReseller>,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = unusedTheme => ({
  root: {
    border: '1px solid rgba(0,0,0,.15)',
  },
});

const ResellerLocations = ({
  name,
  resellers,
  className,
  classes,
  ...props
}: Props): React.Node => {
  return (
    <ExpansionPanel
      elevation={0}
      className={classnames(classes.root, className)}
      {...props}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="primary" variant="button">
          <FormattedMessage
            {...messages.resellerLocations}
            values={{ reseller: name }}
          />
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={6}>
          {map(resellers, (reseller, index: number) => {
            return (
              <Grid item xs={12} sm={4} md={3} key={index || reseller?.data?.name?.text}>
                <Typography variant="h6">
                  {reseller?.data?.name?.text}
                </Typography>
                <Typography>{reseller?.data?.address?.text}</Typography>
                <Typography>
                {reseller?.data?.city?.text}
                  ,
                  {' '}
                  {reseller?.data?.country?.document?.data?.name?.text}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ResellerLocations.defaultProps = {
  className: undefined,
  resellers: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'ResellerLocations' })(ResellerLocations);
