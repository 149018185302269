// @flow
import type { Styles } from './types';

const styles: Styles = theme => ({
  root: {},
  wrapper: {
    padding: theme.spacing(2),
    border: '1px solid rgba(0,0,0,.15)',
    background: 'rgb(246, 246, 246)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: `${theme.spacing(2)}px`,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  selector: {
    minWidth: '15rem',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& .MuiSelect-root': {
      background: theme.palette.common.white,
    },
    '& .MuiInputBase-input': {
      textAlign: 'initial',
    },
  },
  results: {
    flexGrow: 2,
    textAlign: 'initial',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
  input: {
    minWidth: '15rem',
    '& .MuiOutlinedInput-root': {
      background: theme.palette.common.white,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
});

export default styles;
