// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'reseller';

export default {
  types: ({
    id: `${prefix}.labels.types`,
    defaultMessage: 'TIPO DE DISTRIBUIDOR',
    description: 'Reseller types',
  }: MessageDescriptor),
  resellerLocation: ({
    id: `${prefix}.labels.resellerLocation`,
    defaultMessage: 'Ver ubicación',
    description: 'Reseller location',
  }: MessageDescriptor),
  resellerLocations: ({
    id: `${prefix}.labels.resellerLocations`,
    defaultMessage: 'Otras ubicaciones de {reseller}',
    description: 'Reseller types',
  }: MessageDescriptor),
};
