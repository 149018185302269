// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles';
import map from 'lodash/map';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import partition from 'lodash/partition';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { Loader } from '@googlemaps/js-api-loader';

import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import BodySection from '~plugins/material-ui/components/BodySection';
import Header from '~components/Header';
import FooterContactForm from '~components/FooterContactForm';
import useSetAppBarState from '~components/AppBar/hooks/useSetAppBarState';
import Reseller from '~components/Reseller';
import ResellersFilters from '~components/ResellersFilters';
import useGroupedResellerssByCode from '~hooks/useGroupedResellerssByCode';

import type { PrismicResellersPage } from '~schema';

export type ClassKey = 'root' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicResellersPage>>,
};

const styles = (unusedTheme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    overflow: 'hidden',
  },
  bodySection: {},
});

// Use asks the user for permission to read device's location
const getLocation = callback => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      // success function
      position => {
        if (callback) {
          callback({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
        }
      },
      // error function
      err => {
        console.error(`ERROR(${err.code}): ${err.message}`);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        // location can be up to 1 minute old (60000ms)
        maximumAge: 60000,
      },
    );
  } else {
    console.error('Geolocation is not supported by this browser.');
  }
};

const ResellersPage = ({ classes, data, node }: Props) => {
  const [user$location, user$setLocation] = React.useState({});
  const [user$postcode, user$setPostcode] = React.useState('');
  const [maps$response, maps$setResponse] = React.useState({});
  const [noOfResellers, setNoOfResellers] = React.useState(0);

  const resellersInfo = data?.allPrismicReseller?.nodes;
  const countriesInfo = data?.allPrismicCountry?.nodes;
  const resellersByCode = useGroupedResellerssByCode(resellersInfo);
  const [selectedCountry, setSelectedCountry] = React.useState(
    Object.keys(resellersByCode)[0],
  );

  const debouncePostcodeInput = React.useMemo(
    () => debounce(inputValue => user$setPostcode(inputValue), 400),
    [],
  );

  useSetAppBarState({
    overlayColor: node?.data?.overlay_color,
    backgroundImage: node?.data?.background_image,
  });

  // Default method: capture user location by IP
  React.useEffect(() => {
    fetch('https://ipinfo.io/json?token=8f3e99c3ff0b67')
      .then(response => response.json())
      .then(responseData => {
        if (
          find(
            Object.keys(resellersByCode),
            code => code === responseData.country,
          )
        ) {
          setSelectedCountry(responseData.country);
        }
      })
      .catch(error => console.error('Error:', error));
  }, []);

  // Ask for user's location
  React.useEffect(() => {
    getLocation(user$setLocation);
  }, []);

  React.useEffect(() => {
    if (!isEmpty(user$location) || !!user$postcode) {
      // Build array of addresses to be sent to the Google Maps API
      const resellerAddresses = resellersByCode[selectedCountry]
        .reduce((agg, curr) => {
          return [
            ...agg,
            `${curr?.data?.address?.text}, ${curr?.data?.city?.text}, ${curr?.data?.country?.document?.data?.name?.text}`,
          ];
        }, [])
        .filter(Boolean);

      const distanceCallback = (response, status) => {
        if (status === 'OK') {
          maps$setResponse(response);
        }
      };

      const distanceRequest = new Loader({
        apiKey: process.env.GATSBY_GOOGLE_MAPS_API_TOKEN,
      });

      const countryName =
        resellersByCode[selectedCountry][0]?.data?.country?.document?.data?.name
          ?.text || '';

      const userPosition = !!user$postcode
        ? `${user$postcode}, ${countryName}`
        : user$location;

      distanceRequest.load().then(google => {
        const service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [userPosition],
            destinations: resellerAddresses,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
          },
          distanceCallback,
        );
      });

      // number to be diplayed in the filter bar
      setNoOfResellers(resellersByCode[selectedCountry]?.length);
    } else {
      setNoOfResellers(0);
    }
  }, [user$postcode, user$location, selectedCountry]);

  const distancedResellers = map(
    resellersByCode[selectedCountry],
    // return resellers with the added distance from user to be sorted by it
    (reseller, index: number) => ({
      ...reseller,
      distanceFromUser: isNumber(
        maps$response?.rows?.[0]?.elements?.[index]?.distance?.value,
      )
        ? maps$response?.rows?.[0]?.elements?.[index]?.distance
        : null,
    }),
  );

  const splitResellers = partition(distancedResellers, reseller =>
    isNumber(reseller.distanceFromUser?.value),
  );

  const sortedResellers = [
    // resellers with a number in distance are sorted by how close to the user they are
    ...sortBy(splitResellers[0], [
      reseller => reseller?.distanceFromUser?.value,
    ]),
    // resellers without distance from user are sorted by name
    ...sortBy(splitResellers[1], [reseller => reseller?.data?.name]),
  ];

  return (
    <div className={classes.root}>
      <Header
        componentVariant="simple"
        title={node?.data?.title}
        subtitle={node?.data?.subtitle}
        backgroundImage={node?.data?.background_image}
        overlayColor={node?.data?.overlay_color}
      />
      <BodySection className={classes.bodySection}>
        <ResellersFilters
          countries={resellersByCode}
          countriesInfo={countriesInfo}
          results={noOfResellers}
          value={selectedCountry}
          onChange={React.useCallback(event => {
            if (event.target && typeof event.target === 'object') {
              // $FlowFixMe --> Reason: value not defined event.target. Could not check for insteanceof HTMLSelect as MUI returns a plain object
              setSelectedCountry(event.target.value);
            }
          }, [])}
          onInput={event => {
            if (event.target && typeof event.target === 'object') {
              // $FlowFixMe --> Reason: value not defined event.target. Could not check for insteanceof HTMLSelect as MUI returns a plain object
              debouncePostcodeInput(event.target.value);
            }
          }}
        />
        {(!isEmpty(user$location) || !!user$postcode) &&
          map(sortedResellers, (reseller, index: number) => (
            <Reseller
              key={index || reseller?.data?.name}
              data={reseller?.data}
              resellerId={reseller?.id}
              resellersInfo={resellersInfo}
              distanceFromUser={reseller?.distanceFromUser?.text}
              userPostcode={user$postcode}
            />
          ))}
      </BodySection>
      <FooterContactForm currentPage={node?.data?.name?.text} />
    </div>
  );
};

export default compose(
  withNodePage<PrismicResellersPage, *>({
    getNode: data => data?.prismicResellersPage,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'ResellersPage' }),
)(ResellersPage);

export const query = graphql`
  query ResellersPageQuery($prismicId: ID, $prismicLocaleId: String) {
    prismicResellersPage(prismicId: { eq: $prismicId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        title {
          text
          html
        }
        subtitle {
          text
          html
        }
        background_image {
          alt
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
        overlay_color
      }
    }
    allPrismicReseller(
      filter: { lang: { eq: $prismicLocaleId } }
      sort: { fields: data___name___text, order: ASC }
    ) {
      nodes {
        id
        lang
        uid
        first_publication_date
        data {
          name {
            text
            html
          }
          address {
            text
            html
          }
          city {
            text
            html
          }
          country {
            id
            document {
              ... on PrismicCountry {
                id
                data {
                  name {
                    text
                    html
                  }
                  code {
                    text
                    html
                  }
                }
              }
            }
          }
          zip_code {
            text
            html
          }
          reseller_group {
            text
            html
          }
          reseller_type {
            type {
              text
              html
            }
          }
          location_url {
            link_type
            target
            url
          }
        }
      }
    }
    allPrismicCountry {
      nodes {
        data {
          name {
            text
            html
          }
          code {
            text
            html
          }
        }
      }
    }
  }
`;
