// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'filter';

export default {
  results: ({
    id: `${prefix}.labels.results`,
    defaultMessage: '{results} resultados',
    description: 'Number of results',
  }: MessageDescriptor),
  postcode: ({
    id: `${prefix}.labels.postcode`,
    defaultMessage: 'Código postal',
    description: 'Text shown in the postcode input',
  }: MessageDescriptor),
  enterPostcode: ({
    id: `${prefix}.labels.enterPostcode`,
    defaultMessage: 'Por favor introduce un código postal válido',
    description: 'Text shown before the user has entered a valid postcode',
  }: MessageDescriptor),
};
