// @flow
import * as React from 'react';
import withStyles from '@plugins/material-ui/hocs/withStyles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import reject from 'lodash/reject';

import RichText from '~plugins/prismic/components/RichText';
import FieldLink from '~plugins/prismic/components/FieldLink';

import type { Props } from './types';
import styles from './styles';
import messages from './messages';
import ResellerLocations from './ResellerLocations';
import ResellerDialog from './ResellerDialog';

const Reseller = ({
  data,
  resellersInfo,
  className,
  classes,
  resellerId,
  distanceFromUser,
  userPostcode,
  ...props
}: Props): React.Node => {
  const [open, setOpen] = React.useState(false);
  const groupedResellersByFamily = groupBy(
    resellersInfo,
    'data.reseller_group.text',
  );

  const handleOpen = React.useCallback(
    (event: SyntheticEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setOpen(true);
    },
    [],
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const resellerName = `${data?.name?.text} ${data?.city?.text}`;

  return (
    <div className={classnames(classes.root, className)} {...props}>
      <Container className={classes.wrapper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" variantMapping={{ h4: 'h3' }}>
              {resellerName}
              {!!distanceFromUser && (
                <>
                  {' '}
                  <Chip
                    label={distanceFromUser}
                    color="primary"
                    variant="outlined"
                  />
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} md={9}>
            <RichText {...data?.address} />
            <RichText {...data?.city} />
            <Typography>{data?.country?.document?.data?.name?.text}</Typography>
            {data?.location_url ? (
              <Button
                component={FieldLink}
                field={data?.location_url}
                aria-label={data?.name?.text}
                color="primary"
                startIcon={<LocationOnIcon />}
              >
                <FormattedMessage {...messages.resellerLocation} />
              </Button>
            ) : null}
          </Grid>
          {data?.reseller_type?.length > 0 ? (
            <Grid item xs={12} sm={5} md={3}>
              <Typography variant="h6" gutterBottom>
                <FormattedMessage {...messages.types} />
              </Typography>
              {map(data?.reseller_type, (type, index: number) => (
                <Chip
                  key={index}
                  label={type?.type?.text}
                  className={classes.type}
                />
              ))}
            </Grid>
          ) : null}
          {data?.reseller_group?.text &&
            groupedResellersByFamily[data.reseller_group.text].length > 1 && (
              <Grid item xs={12}>
                <ResellerLocations
                  name={data?.name?.text}
                  resellers={reject(
                    groupedResellersByFamily[data.reseller_group.text],
                    {
                      id: resellerId,
                    },
                  )}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpen}
              aria-label="contact"
              disableRipple
            >
              Contactar
            </Button>
          </Grid>
        </Grid>
      </Container>
      <ResellerDialog
        title={resellerName}
        postcode={userPostcode}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
};

Reseller.defaultProps = {
  className: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'Reseller' })(Reseller);
