// @flow
import * as React from 'react';
import withStyles, {
  type StyleRulesCallback,
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import ResellerForm from '~components/ResellerForm';
import useResellerForm from '~components/ResellerForm/useResellerForm';
import useResellerFormValidationSchema from '~components/ResellerForm/useResellerFormValidationSchema';

export type ClassKey = 'root' | 'title' | 'form' | 'closeButton';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  className?: string,
  title: ?string,
  postcode?: ?string,
  open: boolean,
  onClose: () => void,
};

export type Styles = StyleRulesCallback<Theme, Props, ClassKey>;

export const styles: Styles = theme => ({
  root: {
    '& .MuiPaper-root': {
      padding: theme.spacing(8, 4, 11, 4),
    },
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  form: {},
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.black,
  },
});

const FORM_NAME = 'Resellers contact';
const CONTENT_SOURCE = 'Formulario Reseller';

const ResellerDialog = ({
  title,
  postcode,
  open,
  onClose,
  className,
  classes,
  ...props
}: Props): React.Node => {
  const resellerForm = useResellerForm();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classnames(classes.root, className)}
      {...props}
    >
      <IconButton
        disableRipple
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h4" className={classes.title}>
        {title}
      </Typography>
      <ResellerForm
        formName={FORM_NAME}
        contentSource={CONTENT_SOURCE}
        node={resellerForm}
        company={title}
        postCode={postcode}
        validationSchema={useResellerFormValidationSchema(resellerForm)}
        classes={React.useMemo(
          () => ({
            root: classes.form,
          }),
          [classes],
        )}
      />
    </Dialog>
  );
};

ResellerDialog.defaultProps = {
  className: undefined,
  postcode: undefined,
};

export default withStyles<*, *, Props>(styles, { name: 'ResellerDialog' })(ResellerDialog);
