// @flow
import type { MessageDescriptor } from 'react-intl';

const prefix = 'global';

export default {
  allCountriesKey: ({
    id: `${prefix}.labels.allCountriesKey`,
    defaultMessage: 'Todos los países',
    description: 'All countries key',
  }: MessageDescriptor),
};
